import { useMemo } from 'react'
import { useQueryClient } from 'react-query'
import { useTheme } from 'styled-components'
import DatasetDetailsTable from '../../datasetDetailsTable/components/DatasetDetailsTable'
import { DATASET_QUERY_KEY } from '../queries/useDatasetQuery'
import { TDataset } from '../../../../definitions/dataset'

const rows = [
  {
    Header: 'Host',
    accessor: 'host'
  },
  {
    Header: 'Other affiliated organizations',
    accessor: 'organizations'
  },
  {
    Header: 'Granularity',
    accessor: 'granularity'
  },
  {
    Header: 'Collection mechanism',
    accessor: 'collection_mechanism'
  },
  {
    Header: 'Population',
    accessor: 'population'
  },
  {
    Header: 'Record level',
    accessor: 'record_level'
  },
  {
    Header: 'Available weights',
    accessor: 'additional_weights'
  },
  {
    Header: 'Time design',
    accessor: 'time_design'
  },
  {
    Header: 'Current periodicity',
    accessor: 'current_periodicity'
  },
  {
    Header: 'Years data available from dataset host',
    accessor: 'years_available'
  },
  {
    Header: 'Years of metadata included in Health Data Select',
    accessor: 'years_included'
  },
  {
    Header: 'Lowest geographic level',
    accessor: 'lowest_geo_level'
  },
  {
    Header: 'Geographic variables',
    accessor: 'geo_var'
  },
  {
    Header: 'Content focus',
    accessor: 'content_focus'
  },
  {
    Header: 'Accessibility',
    accessor: 'accessibility'
  }
]

const useTableData = () => {
  const data = useQueryClient().getQueryData<TDataset>(DATASET_QUERY_KEY)
  return useMemo(
    () => (data ? [data] : []),
    [data]
  )
}

const Table = () => {
  const data = useTableData()
  const { colors } = useTheme()
  return (
    <DatasetDetailsTable
      data={data}
      rows={rows}
      sx={{
        '.MuiTableRow-root .MuiTableCell-root': {
          padding: '10px'
        },
        '.MuiTableRow-root .MuiTableCell-root:first-child': {
          borderRight: `1px solid ${colors.grey.primary}`
        },
        '& .MuiTableCell-root': {
          borderTop: `1px solid ${colors.grey.primary}`
        }
      }}
    />
  )
}

export default Table
