import { CellProps, Column } from 'react-table'
import { useNavigate } from 'react-router-dom'
import { FC, memo, useMemo } from 'react'
import styled from 'styled-components'
import StripedTable from '../../../shared/stripedTable/StripedTable'
import { CompareButton } from '../../compareButton/CompareButton'
import { useComparedVariablesContext } from '../../valuesComparison/state/useComparedVariablesState'
import { ROUTES } from '../../../../constants/routes'
import VariableSelectCell from './VariableSelectCell'
import { useKeywordsStateContext } from '../../datasets/state/useKeywordsState'
import TextHighlighter from '../../../shared/TextHighlighter'
import VariableCodeCell from './VariableCodeCell'
import Button from '../../../shared/Button'
import { prepareTableData } from '../utils'
import Hint from '../../../shared/hint/Hint'
import { getPrevRowIndex } from '../../../../utils/table'
import { TRowData } from '../definitions'

const ButtonWrapStyled = styled.div`
  display: flex;
  gap: 8px;
`

const HeaderWrapper = styled.div`
  display: flex;
  align-items: center;
  gap: 4px;
`

const HiddenCellData = styled.div<{
  hideValue?: boolean
}>`
  opacity: ${({ hideValue }) => (hideValue ? 0 : 1)};
  transition: 0.2s all;
`

const columns: Column[] = [
  {
    Header: 'Search term',
    accessor: 'keyword',
    Cell: ({ value, row, rows }: any) => {
      const prevRow = rows[getPrevRowIndex<TRowData>(rows, row.id)]
      return <HiddenCellData hideValue={prevRow?.original.keyword === value || row.original.keywordContinues}>{value}</HiddenCellData>
    }
  },
  {
    Header: 'Year',
    accessor: 'year',
    Cell: ({ value, row, rows }: any) => {
      const { versionContinues, file: currentRowFile } = row.original
      const prevRow = rows[getPrevRowIndex<TRowData>(rows, row.id)]
      const isHiddenValue = row.id === '0' ? versionContinues : prevRow?.original.file === currentRowFile && prevRow?.original.year === value

      return <HiddenCellData hideValue={isHiddenValue}>{value}</HiddenCellData>
    }
  },
  {
    Header: 'File',
    accessor: 'file',
    Cell: ({ value, row, rows }: any) => {
      const { versionContinues, year: currentRowYear } = row.original
      const prevRow = rows[getPrevRowIndex<TRowData>(rows, row.id)]
      const isHiddenValue = row.id === '0'
        ? versionContinues
        : prevRow?.original.file === value && prevRow?.original.year === currentRowYear

      return <HiddenCellData hideValue={isHiddenValue}>{value}</HiddenCellData>
    }
  },
  {
    Header: () => (
      <HeaderWrapper>
        <span>Variable Code</span>
        <Hint
          iconWidth={20}
          withDefaultColor
          placement="top"
          title="Click on a variable code to see the possible values for that variable."
          maxWidth={208}
        />
      </HeaderWrapper>
    ),
    accessor: 'varCode',
    Cell: (props: CellProps<any>) => <VariableCodeCell {...props} />
  },
  {
    Header: 'Variable Description',
    accessor: 'varDescription',
    Cell: ({ value }) => {
      const {
        comparedKeywords: [keywordData]
      } = useKeywordsStateContext()
      return <TextHighlighter text={value} searchQuery={keywordData?.name || ''} />
    }
  },
  {
    Header: () => {
      const navigate = useNavigate()
      const { comparedVariables, clear } = useComparedVariablesContext()
      return (
        <ButtonWrapStyled>
          <Button
            disabled={!comparedVariables.length}
            onClick={clear}
            variant="secondary"
            sx={{
              padding: '4px 10px',
              fontSize: '13px',
              lineHeight: '22px',
              height: '30px',
              width: 'max-content'
            }}
          >
            Clear all
          </Button>
          <CompareButton disabled={!comparedVariables.length} onClick={() => navigate(ROUTES.COMPARE_VALUES)} text="See values" />
        </ButtonWrapStyled>
      )
    },
    accessor: 'checkbox',
    Cell: (props: CellProps<any>) => <VariableSelectCell {...props} />
  }
]
type TProps = {
  tableData?: any
  stickyOffset?: number
}

const Table: FC<TProps> = ({ tableData, stickyOffset }) => {
  const data = useMemo(() => prepareTableData(tableData ?? []), [tableData])
  return (
    <StripedTable
      data={data}
      columns={columns}
      stickyOffset={stickyOffset}
      isInitiallyExpanded
      stickyHeader
      stickyRawBody
      sx={{
        '& .MuiTableHead-root .MuiTableCell-root': {
          whiteSpace: 'nowrap'
        },
        '& .MuiTableBody-root': {
          '.MuiTableRow-root:hover td > div': {
            opacity: 1
          },
          '.MuiTableCell-root': {
            verticalAlign: 'top',
            '&:last-child': {
              width: '10%',
              textAlign: 'center'
            }
          }
        }
      }}
    />
  )
}

export default memo(Table)
