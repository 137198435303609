import { FC, memo, useLayoutEffect } from 'react'
import { useTheme } from 'styled-components'
import { Column, Row, useExpanded, useTable } from 'react-table'
import { Table, TableBody, TableCell, TableHead, TableRow, Theme } from '@mui/material'
import { SxProps } from '@mui/system'
import { getPrevRowIndex, isEvenRow } from '../../../utils/table'
import { TRowData } from '../../features/valuesComparison/definitions'

type TProps = {
  data: any[]
  columns: Column[]
  isInitiallyExpanded?: boolean
  sx?: SxProps<Theme>
  stickyHeader?: boolean
  stickyRawBody?: boolean
  setExpanded?: (rows: Row<TRowData>[]) => void
  handleStickIndexBody?: (currentIndex: number) => any
  stickyOffset?: number
}

const borderedColumnIds = ['varCode', 'varDescription', 'valCode', 'valDescription']

const StripedTable: FC<TProps> = ({
  data,
  columns,
  isInitiallyExpanded,
  sx,
  stickyHeader,
  stickyRawBody,
  setExpanded,
  handleStickIndexBody,
  stickyOffset
}) => {
  const { colors } = useTheme()
  const { getTableProps, prepareRow, headerGroups, getTableBodyProps, toggleAllRowsExpanded, rows } = useTable(
    {
      columns,
      data
    },
    useExpanded
  )

  useLayoutEffect(() => {
    if (isInitiallyExpanded) {
      toggleAllRowsExpanded()
    }
    setExpanded?.(rows as Row<TRowData>[])
  }, [data, isInitiallyExpanded])

  const rowsWithName = rows.filter(({ values }) => values?.name || values?.keyword)

  return (
    <Table
      stickyHeader={stickyHeader}
      {...getTableProps()}
      sx={{
        '& .MuiTableCell-root': {
          fontSize: '14px',
          lineHeight: '16px',
          border: 'none',
          minHeight: '32px',
          color: colors.black,
          paddingLeft: '20px',
          '&:last-of-type': {
            paddingRight: '20px'
          }
        },
        ...sx
      }}
    >
      <TableHead
        sx={{
          '& .MuiTableCell-root': {
            fontWeight: 500,
            paddingTop: '0px',
            paddingBottom: '8px',
            verticalAlign: 'middle',
            height: stickyHeader ? '40px' : 'auto',
            zIndex: stickyHeader ? 999999999999 : 1,
            position: stickyHeader ? 'sticky' : 'static',
            top: stickyHeader ? `${stickyOffset || 174}px` : 'auto'
          }
        }}
      >
        {headerGroups.map((headerGroup) => (
          <TableRow {...headerGroup.getHeaderGroupProps()}>
            {headerGroup.headers.map((tableGroup) => (
              <TableCell {...tableGroup.getHeaderProps()}>{tableGroup.render('Header')}</TableCell>
            ))}
          </TableRow>
        ))}
      </TableHead>
      <TableBody
        {...getTableBodyProps()}
        sx={{
          '& .MuiTableCell-root': {
            paddingTop: '4px',
            paddingBottom: '4px',
            height: '32px'
          }
        }}
      >
        {rows.map((row) => {
          prepareRow(row)
          const getIndex = rowsWithName.findIndex((rowWithName) => rowWithName.id === row.id)
          const index = handleStickIndexBody ? handleStickIndexBody(getIndex).index : getIndex

          const prevRow = rows[getPrevRowIndex(rows, row.id)] as Row<TRowData>

          return (
            <TableRow
              {...row.getRowProps()}
              sx={
                index >= 0 && stickyRawBody
                  ? {
                    backgroundColor: isEvenRow(row) ? colors.grey.light : 'white',
                    height: handleStickIndexBody?.(getIndex)?.isHidden ? 'auto' : '56px'
                  }
                  : undefined
              }
            >
              {row.cells.map((cell) => (
                <TableCell
                  sx={{
                    backgroundColor: isEvenRow(row) ? colors.grey.light : undefined,
                    borderTop:
                      (row?.original as TRowData)?.valCode
                      && borderedColumnIds.includes(cell.column.id)
                      && prevRow?.original?.varCode !== (row.original as TRowData).varCode
                        ? '1px solid #d2d2d8 !important'
                        : 'none'
                  }}
                  {...cell.getCellProps()}
                >
                  {cell.render('Cell')}
                </TableCell>
              ))}
            </TableRow>
          )
        })}
      </TableBody>
    </Table>
  )
}

export default memo(StripedTable)
