import styled from 'styled-components'
import { useEffect, useLayoutEffect, useRef, useState } from 'react'
import { useNavigate } from 'react-router-dom'
import Card from '../../../shared/Card'
import BackLink from '../../backLink/BackLink'
import { ROUTES } from '../../../../constants/routes'
import ComparisonDetails from '../../comparisonDetails/ComparisonDetails'
import Table from './Table'
import { ReactComponent as DatabaseIcon } from '../../../../assets/Database.svg'
import { ReactComponent as TableIcon } from '../assets/Table.svg'
import { useComparedVariablesContext } from '../state/useComparedVariablesState'
import Spinner from '../../../shared/Spinner'
import { TableRowsProvider, useTableRowsContext } from '../state/useTableRows'
import { DetailsStateProvider, useDetailsStateContext } from '../state/useDetailsState'
import IconButton from '../../../shared/IconButton'
import { ReactComponent as EditIcon } from '../../../../assets/Edit.svg'
import AdvancedPagination from '../../advancedPagination/components/AdvancedPagination'
import { PaginationProvider } from '../../../../hooks/usePagination'
import { prepareDataForSubmit } from '../utils'
import Hint from '../../../shared/hint/Hint'
import { HINT_TEXT } from '../constants'
import { useFetchValues } from '../hooks/useFetchValues'

const WrapperStyled = styled.div`
  height: 100%;
  display: flex;
  flex-direction: column;
`

const CardStyled = styled(Card)`
  padding: 0 0 20px 0;
  margin: auto;
  min-width: 1136px;
  max-width: min-content;
  height: 100%;
`

const TitleStyled = styled.span`
  font-size: 18px;
  line-height: 22px;
  color: ${({ theme }) => theme.colors.black};
  margin: 12px 0 8px;
  display: block;
`

const HeaderStyled = styled.div`
  padding: 20px 20px 16px;
  position: sticky;
  z-index: 10001;
  width: 100%;
  top: 0;
  background: white;
`

const SpinnerStyled = styled(Spinner)`
  margin-top: 20px;
`

const NavigationWrapStyled = styled.div`
  display: flex;
  justify-content: space-between;
`

const TitleWrapStyled = styled.div`
  display: flex;
  align-items: center;
  gap: 4px;

  :nth-child(2) {
    margin-top: 4px;
  }
`

export const useEmptyDataGuard = () => {
  const { comparedVariables, comparedVariable } = useComparedVariablesContext()
  const navigate = useNavigate()

  useEffect(() => {
    if (!comparedVariables.length && !comparedVariable) {
      navigate(ROUTES.DATASETS)
    }
  }, [comparedVariables, comparedVariable])
}

const ValuesComparison = () => {
  useEmptyDataGuard()
  const { tableRows } = useTableRowsContext()
  const { variables, datasets } = useDetailsStateContext()
  const navigate = useNavigate()
  useFetchValues()

  const [headerHeight, setHeaderHeight] = useState(0)

  const headerRef = useRef<HTMLDivElement | null>(null)

  useLayoutEffect(() => {
    if (variables && datasets) {
      setHeaderHeight(headerRef.current?.clientHeight ?? 0)
    }
  }, [variables, datasets])

  return (
    <WrapperStyled>
      <CardStyled>
        <HeaderStyled ref={headerRef}>
          <NavigationWrapStyled>
            <BackLink link={-1} pageName="variable results" />
            <IconButton
              onClick={() => navigate(ROUTES.DATASETS)}
              sx={{
                display: 'flex',
                gap: '8px',
                fontSize: '16px',
                fontWeight: '500',
                lineHeight: '18.75px'
              }}
            >
              <EditIcon />
              Edit search
            </IconButton>
          </NavigationWrapStyled>

          {tableRows && (
            <>
              <TitleWrapStyled>
                <TitleStyled>Possible values for the variable(s) you selected</TitleStyled>
                <Hint withDefaultColor placement="top" title={HINT_TEXT} maxWidth={400} />
              </TitleWrapStyled>
              <ComparisonDetails
                rows={[
                  {
                    icon: <TableIcon />,
                    name: 'Variable(s)',
                    items: variables
                  },
                  {
                    icon: <DatabaseIcon />,
                    name: 'Dataset(s)',
                    items: datasets
                  }
                ]}
              />
            </>
          )}
        </HeaderStyled>
        {!tableRows ? <SpinnerStyled /> : <Table stickyOffset={headerHeight} />}
      </CardStyled>
      {tableRows && <AdvancedPagination />}
    </WrapperStyled>
  )
}

export default () => {
  const { comparedVariables, comparedVariable } = useComparedVariablesContext()
  const keywords = prepareDataForSubmit(comparedVariables.length ? comparedVariables : comparedVariable ? [comparedVariable] : [])

  return (
    <PaginationProvider rowsPerPage={75} initialRowsCount={keywords[0]?.variablesId?.length}>
      <DetailsStateProvider>
        <TableRowsProvider>
          <ValuesComparison />
        </TableRowsProvider>
      </DetailsStateProvider>
    </PaginationProvider>
  )
}
