import styled from 'styled-components'
import { useNavigate } from 'react-router-dom'
import { useEffect, useLayoutEffect, useRef, useState } from 'react'
import pluralize from 'pluralize'
import { useQueryClient } from 'react-query'
import Card from '../../../shared/Card'
import BackLink from '../../backLink/BackLink'
import { ROUTES } from '../../../../constants/routes'
import ComparisonDetails from '../../comparisonDetails/ComparisonDetails'
import { ReactComponent as DatabaseIcon } from '../../../../assets/Database.svg'
import { ReactComponent as KeyIcon } from '../../../../assets/Key.svg'
import { ReactComponent as EditIcon } from '../../../../assets/Edit.svg'
import Table from './Table'
import { useKeywordsStateContext } from '../../datasets/state/useKeywordsState'
import { KEYWORD_DETAILS_QUERY_KEY, useKeywordDetailsQuery } from '../../../../queries/useKeywordDetailsQuery'
import Spinner from '../../../shared/Spinner'
import { getVariablesFromDatasets } from '../utils'
import IconButton from '../../../shared/IconButton'
import Hint from '../../../shared/hint/Hint'
import { HINT_TEXT } from '../constants'
import { PaginationProvider, usePaginationContext } from '../../../../hooks/usePagination'
import AdvancedPagination from '../../advancedPagination/components/AdvancedPagination'
import { TDatasetsVariablesData } from '../../../../definitions/variable'

const WrapperStyled = styled.div`
  height: 100%;
  display: flex;
  flex-direction: column;
`

const CardStyled = styled(Card)`
  padding: 0 0 20px 0;
  min-width: 1136px;
  max-width: min-content;
  margin: auto;
  height: 100%;
`

const HeaderStyled = styled.div`
  padding: 20px 20px 16px;
  position: sticky;
  z-index: 1000001;
  width: 100%;
  top: 0;
  background: white;
`

const TitleStyled = styled.span`
  font-size: 18px;
  line-height: 22px;
  color: ${({ theme }) => theme.colors.black};
  margin: 12px 0 8px;
  display: block;
`

const NavigationWrapStyled = styled.div`
  display: flex;
  justify-content: space-between;
`

const BoldTextStyled = styled.span`
  font-weight: 500;
`

const SpinnerStyled = styled(Spinner)`
  margin: 20px 0;
`

const TitleWrapStyled = styled.div`
  display: flex;
  align-items: center;
  gap: 4px;

  :nth-child(2) {
    margin-top: 4px;
  }
`

const useDetailsRows = (datasets: any) => {
  const [keywordData] = useKeywordsStateContext().comparedKeywords

  if (!datasets) {
    return []
  }
  return [
    {
      icon: <KeyIcon />,
      name: 'Search term',
      items: [keywordData?.name]
    },
    {
      icon: <DatabaseIcon />,
      name: 'Dataset(s)',
      items: datasets?.map(({ dataset_name }: any) => dataset_name) ?? []
    }
  ]
}

const useEmptyDataHandler = () => {
  const navigate = useNavigate()
  const [keywordData] = useKeywordsStateContext().comparedKeywords

  useEffect(() => {
    if (!keywordData) {
      navigate(ROUTES.DATASETS)
    }
  }, [keywordData])
}

function useUseKeywordDetailsData() {
  const { startCount, rowsPerPage, page } = usePaginationContext()
  const {
    comparedKeywords: [keywordData]
  } = useKeywordsStateContext()
  return useKeywordDetailsQuery(
    {
      name: keywordData?.name,
      variablesId: keywordData?.variablesId
    },
    false,
    true,
    startCount,
    rowsPerPage,
    page
  )
}

const KeywordDetails = () => {
  useEmptyDataHandler()
  const {
    comparedKeywords: [keywordData]
  } = useKeywordsStateContext()
  const { data: datasets } = useUseKeywordDetailsData()
  const queryClient = useQueryClient()
  const allDataSets = queryClient.getQueryData<TDatasetsVariablesData[]>([KEYWORD_DETAILS_QUERY_KEY])

  const variables = getVariablesFromDatasets(allDataSets ?? [])
  const detailsRows = useDetailsRows(allDataSets)
  const navigate = useNavigate()

  const [headerHeight, setHeaderHeight] = useState(0)

  const headerRef = useRef<HTMLDivElement | null>(null)

  useLayoutEffect(() => {
    if (variables && datasets) {
      setHeaderHeight(headerRef.current?.clientHeight ?? 0)
    }
  }, [variables, datasets])

  return (
    <WrapperStyled>
      <CardStyled>
        <HeaderStyled ref={headerRef}>
          <NavigationWrapStyled>
            <BackLink link={ROUTES.DATASETS} pageName="main search results" />
            <IconButton
              onClick={() => navigate(ROUTES.DATASETS)}
              sx={{
                display: 'flex',
                gap: '8px',
                fontSize: '16px',
                fontWeight: '500',
                lineHeight: '18.75px'
              }}
            >
              <EditIcon />
              Edit search
            </IconButton>
          </NavigationWrapStyled>

          {datasets && (
            <>
              <TitleWrapStyled>
                <TitleStyled>
                  Your search for variables addressing <BoldTextStyled>{`"${keywordData?.name}"`}</BoldTextStyled> found{' '}
                  <BoldTextStyled>{pluralize('variable', variables.length, true)}</BoldTextStyled> in{' '}
                  <BoldTextStyled>{pluralize('dataset', allDataSets?.length, true)}</BoldTextStyled>.
                </TitleStyled>
                <Hint withDefaultColor placement="top" title={HINT_TEXT} maxWidth={400} />
              </TitleWrapStyled>
              <ComparisonDetails rows={detailsRows} />
            </>
          )}
        </HeaderStyled>
        {datasets ? <Table tableData={datasets} stickyOffset={headerHeight} /> : <SpinnerStyled />}
      </CardStyled>
      <AdvancedPagination />
    </WrapperStyled>
  )
}

export default () => {
  const {
    comparedKeywords: [keywordData]
  } = useKeywordsStateContext()
  const { isLoading } = useUseKeywordDetailsData()
  if (isLoading) {
    return (
      <WrapperStyled>
        <CardStyled>
          <SpinnerStyled />
        </CardStyled>
      </WrapperStyled>
    )
  }
  return (
    <PaginationProvider initialRowsCount={keywordData?.variablesId.length} rowsPerPage={75}>
      <KeywordDetails />
    </PaginationProvider>
  )
}
