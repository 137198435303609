import { FC, useCallback, useLayoutEffect, useMemo, useState } from 'react'
import { Column, Row } from 'react-table'
import styled, { useTheme } from 'styled-components'
import StripedTable from '../../../shared/stripedTable/StripedTable'
import { useTableRowsContext } from '../state/useTableRows'
import { useOpenDataset } from '../../../../hooks/useOpenDataset'
import Link from '../../../shared/Link'
import { useKeywordsStateContext } from '../../datasets/state/useKeywordsState'
import TextHighlighter from '../../../shared/TextHighlighter'
import Button from '../../../shared/Button'
import { ReactComponent as ArrowUpIcon } from '../../../../assets/ArrowUp.svg'
import { ReactComponent as ArrowDownIcon } from '../../../../assets/ArrowDown.svg'
import { TRowData } from '../definitions'
import { groupSentence } from '../../../../utils/string'
import { checkIsMatch, TOGGLE_BUTTON_VAL_DESCRIPTION } from '../utils'
import { getPrevRowIndex } from '../../../../utils/table'

const HiddenCellData = styled.div<{
  hideValue?: boolean
}>`
  opacity: ${({ hideValue }) => (hideValue ? 0 : 1)};
  transition: 0.2s all;
`

const DescriptionCellWrapper = styled.div`
  width: 250px;
`

export function prepareSearchQuery(keyword: string) {
  if (!keyword) return ''

  return groupSentence(keyword).map((word) => word.toString().toLowerCase().trim())
}

function getElementRowId(expanded: any, currentRowId: string) {
  return Object.keys(expanded)?.find((key) => {
    // eslint-disable-next-line no-unsafe-optional-chaining
    const parent = key?.split('.')[0]
    if (!currentRowId.includes('.')) {
      return Number(parent) === Number(currentRowId)
    }
    return expanded[key].includes(currentRowId)
  })
}

const getPrevRow = (rows: Row<TRowData>[], row: Row<TRowData>) => {
  const prevRowIndex = getPrevRowIndex(rows, row.id)
  return rows[prevRowIndex] && rows[prevRowIndex]?.original.valDescription === TOGGLE_BUTTON_VAL_DESCRIPTION
    ? rows[prevRowIndex - 1]
    : rows[prevRowIndex]
}

function useColumns(notMatchedRows: any) {
  const { comparedKeywords } = useKeywordsStateContext()
  const keywords = useMemo(() => comparedKeywords.map((keyword) => keyword.name), [comparedKeywords])
  const [expanded, setExpanded] = useState<any>({})

  const columns: Column<any>[] = [
    {
      Header: 'Dataset Name − Short Name',
      accessor: 'name',
      Cell: ({ value, row, rows }: any) => {
        const { shortName, id } = row.original
        const { to, state } = useOpenDataset(id)
        const rowId = getElementRowId(expanded, row.id)
        const expandedElement = rowId ? (expanded[rowId] ?? []) : []
        const isHidden = (expandedElement ?? []).includes(row.id)

        const prevRow = getPrevRow(rows, row)

        if (isHidden || !value) {
          return <></>
        }

        return (
          <HiddenCellData hideValue={prevRow && prevRow.original.name === value}>
            <span>{value} - </span>
            <Link to={to} state={state} sx={{ fontWeight: 400 }}>
              {shortName}
            </Link>
          </HiddenCellData>
        )
      }
    },
    {
      Header: 'Year',
      accessor: 'year',
      Cell: ({ value, row, rows }) => {
        const rowId = getElementRowId(expanded, row.id)
        const expandedElement = rowId ? (expanded[rowId] ?? []) : []
        const isHidden = (expandedElement ?? []).includes(row.id)

        if (isHidden || !value) {
          return <></>
        }

        const prevRow = getPrevRow(rows, row)
        const isHiddenValue = prevRow && prevRow.original.file === row.original.file && prevRow?.original.year === value
        return <HiddenCellData hideValue={isHiddenValue}>{value}</HiddenCellData>
      }
    },
    {
      Header: 'File',
      accessor: 'file',
      Cell: ({ value, row, rows }) => {
        const rowId = getElementRowId(expanded, row.id)
        const expandedElement = rowId ? (expanded[rowId] ?? []) : []
        const isHidden = (expandedElement ?? []).includes(row.id)

        if (isHidden || !value) {
          return <></>
        }
        const prevRow = getPrevRow(rows, row)
        const isHiddenValue = prevRow && prevRow.original.file === value && prevRow?.original.year === row.original.year
        return <HiddenCellData hideValue={isHiddenValue}>{value}</HiddenCellData>
      }
    },
    {
      Header: 'Variable Code',
      accessor: 'varCode',
      Cell: ({ value, row, rows }) => {
        const rowId = getElementRowId(expanded, row.id)
        const expandedElement = rowId ? (expanded[rowId] ?? []) : []
        const isHidden = (expandedElement ?? []).includes(row.id)
        if (isHidden || !value) {
          return <></>
        }

        const prevRow = getPrevRow(rows, row)

        return <HiddenCellData hideValue={prevRow && prevRow.original.varCode === value}>{value}</HiddenCellData>
      }
    },
    {
      Header: 'Variable Description',
      accessor: 'varDescription',
      Cell: ({ value, row, rows }) => {
        const {
          comparedKeywords: [keywordData]
        } = useKeywordsStateContext()
        const rowId = getElementRowId(expanded, row.id)
        const expandedElement = rowId ? (expanded[rowId] ?? []) : []
        const isHidden = (expandedElement ?? []).includes(row.id)

        if (isHidden || !value) {
          return <></>
        }

        const prevRow = getPrevRow(rows, row)

        const isHiddenValues = prevRow && prevRow.original.varDescription === value && prevRow.original.varCode === row.original.varCode

        return (
          <HiddenCellData hideValue={isHiddenValues}>
            <DescriptionCellWrapper>
              <TextHighlighter text={value} searchQuery={keywordData?.name} />
            </DescriptionCellWrapper>
          </HiddenCellData>
        )
      }
    },
    {
      Header: 'Value Code',
      accessor: 'valCode',
      Cell: ({ value, row }) => {
        const rowId = getElementRowId(expanded, row.id)
        const expandedElement = rowId ? (expanded[rowId] ?? []) : []
        const isHidden = (expandedElement ?? []).includes(row.id)
        if ((isHidden && !checkIsMatch(keywords, row.original?.valDescription)) || !value) {
          return <></>
        }

        return <span>{value}</span>
      }
    },
    {
      Header: 'Value Description',
      accessor: 'valDescription',
      Cell: ({ value, row }) => {
        const {
          comparedKeywords: [keywordData]
        } = useKeywordsStateContext()
        const { colors } = useTheme()
        const rowId = getElementRowId(expanded, row.id)
        const expandedElement = rowId ? (expanded[rowId] ?? []) : []

        if (value === TOGGLE_BUTTON_VAL_DESCRIPTION) {
          const isExpanded = expanded[row.id]?.length
          return (
            <Button
              sx={{
                padding: '8px 0',
                backgroundColor: 'transparent',
                color: colors.blue.primary,
                fontWeight: 400,
                display: 'flex',
                alignItems: 'center',
                width: 'max-content',
                gap: '4px',
                ':hover': {
                  backgroundColor: 'transparent'
                }
              }}
              variant="primary"
              onClick={() => {
                setExpanded((prev: any) => ({
                  ...prev,
                  [row.id]: prev[row.id]?.length ? [] : notMatchedRows[row.id]
                }))
              }}
            >
              {!isExpanded ? (
                <>
                  <ArrowUpIcon />
                  <div>Display less</div>
                </>
              ) : (
                <>
                  <ArrowDownIcon />
                  <div>Display all values</div>
                </>
              )}
            </Button>
          )
        }
        const isHidden = (expandedElement ?? []).includes(row.id)

        if (!value || isHidden) {
          return <></>
        }

        return (
          <DescriptionCellWrapper>
            <TextHighlighter searchQuery={keywordData?.name} text={value} />
          </DescriptionCellWrapper>
        )
      }
    }
  ]

  return { columns, setExpanded, expanded }
}

const useTableData = () => {
  const { tableRows } = useTableRowsContext()
  return useMemo(() => {
    if (!tableRows) {
      return []
    }
    return tableRows
  }, [tableRows])
}

function useExpandRows() {
  const [notMatchedRows, setNotMatchedRows] = useState<any>({})

  const handleExpand = useCallback((rows: Row<TRowData>[]) => {
    const result = rows.reduce((acc, curr) => {
      let tempIds: string[] = []
      curr.subRows?.forEach(({ id, original }) => {
        if (!original.isMatch && original?.isMatch !== undefined) {
          tempIds.push(id)
        }

        if (original.valDescription === TOGGLE_BUTTON_VAL_DESCRIPTION) {
          acc[id] = tempIds
          tempIds = []
        }
      })

      return acc
    }, {} as any)
    setNotMatchedRows(result)
  }, [])
  return {
    handleExpand,
    notMatchedRows
  }
}

type TProps = {
  stickyOffset?: number
}
const Table: FC<TProps> = ({ stickyOffset }) => {
  const data = useTableData()

  const { notMatchedRows, handleExpand } = useExpandRows()
  const { columns, setExpanded } = useColumns(notMatchedRows)
  useLayoutEffect(() => {
    if (Object.values(notMatchedRows ?? {}).length) {
      setExpanded(notMatchedRows)
    }
  }, [notMatchedRows])

  return (
    <StripedTable
      setExpanded={handleExpand}
      data={data}
      columns={columns}
      stickyOffset={stickyOffset}
      isInitiallyExpanded
      stickyRawBody
      stickyHeader
      sx={{
        '& .MuiTableHead-root .MuiTableCell-root': {
          whiteSpace: 'nowrap'
        },
        '& .MuiTableBody-root': {
          '& .MuiTableRow-root': {
            height: 0,

            '&:hover td > div': {
              opacity: 1
            },
            '& .MuiTableCell-root': {
              verticalAlign: 'top',
              '&:empty': {
                width: '0 !important',
                height: 0,
                margin: 0,
                padding: 0,
                '.MuiTableRow-root': {
                  height: 0
                },
                '&:first-of-type': {
                  width: '0%',
                  height: '0 !important'
                }
              },
              '&:first-of-type': {
                width: '30%'
              },
              '&:last-of-type': {
                overflowWrap: 'anywhere'
              }
            }
          }
        }
      }}
    />
  )
}

export default Table
